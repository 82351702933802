import React from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import { VoteButton } from './vote_button';

export const Votes = (props) => {
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const buttons = [];
    for (var answerId in props.answers) {
        const answer = props.answers[answerId];
        const key = `button${answerId}`;
        buttons.push(
            <VoteButton 
                key={key} 
                enlarge={props.enlarge}
                pollId={props.pollId} 
                question={props.question} 
                buttonLoading={buttonLoading} 
                setButtonLoading={setButtonLoading} 
                answerId={answerId} 
                answerText={answer.text} 
            />
        );
    }

    return (
        <ButtonGroup id="options" orientation="vertical" aria-label="vertical contained button group">
            {buttons}
        </ButtonGroup>
    );
};
