import React, { useEffect } from 'react';
import { ConsoleTable } from './table'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify'
import PrimarySearchAppBar from './console_navbar'
import NiceModal from '@ebay/nice-modal-react';

export const Console = (props) => {
    document.title = 'Polls Console'

    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
       .catch(err => navigate("/console/login"));
    }, [])

    return (
        <NiceModal.Provider>
            <PrimarySearchAppBar/>
            <Box mt={4}/>
            <ConsoleTable />
        </NiceModal.Provider>
    );
};
