import React, { useState, useEffect, useRef, createRef } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { API } from 'aws-amplify'
import { Loader } from '../loader';


export const EditModal = NiceModal.create((props) => {
    const questionTextFieldRef = useRef();
    const descriptionTextFieldRef = useRef();
    const groupTextFieldRef = useRef();
    const answerTextRefs = useRef([])
    const answerIsCorrectRefs = useRef([])

    const modal = useModal();
    const [poll, setPoll] = useState();
    const [isLoading, setLoading] = useState(true);
    const [extra, setExtra] = useState(0);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [discardButtonDisabled, setDiscardButtonDisabled] = useState(false);

    async function saveChanges() {
        setSaveButtonDisabled(true)
        setDiscardButtonDisabled(true)

        const question = questionTextFieldRef.current.value
        const description = descriptionTextFieldRef.current.value
        const group = groupTextFieldRef.current.value

        const data = {
            body: {
                question: question,
                description: description,
                group: group,
                answers: {}
            },
        };

        for(var i=0; i < answerTextRefs.current.length; i++) {
            const answer = answerTextRefs.current[i].current.value
            const isCorrect = answerIsCorrectRefs.current[i].current.checked
            data.body.answers[i] = {text: answer, isCorrect: isCorrect, votes: 0}
        }

        try {
            await API.put('polls', `/poll/update/${props.pollId}`, data)
            modal.resolve()
            modal.remove()
        } catch (error) {
            console.log(error)
        }
    }

    async function fetchPoll() {
        try {
            const data = await API.get('polls', `/poll/${props.pollId}`)
            setPoll(data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPoll()
    }, []);

    if (isLoading) {
        return (
            <Dialog open={modal.visible} >
                <DialogTitle>Editing {props.pollId}</DialogTitle>
                <DialogContent>
                    <Loader />
                </DialogContent>
            </Dialog>
        )
    }

    var answers = [];
    var index = 1;
    for (var answerId in poll.answers) {
        answerTextRefs.current[index - 1] = createRef()
        answerIsCorrectRefs.current[index - 1] = createRef()
        const answer = poll.answers[answerId]

        var isCorrectSwitch = <Switch key={"isCorrect " + index} inputRef={answerIsCorrectRefs.current[index - 1]} />
        if (answer.isCorrect) {
            isCorrectSwitch = <Switch defaultChecked key={"isCorrect " + index} inputRef={answerIsCorrectRefs.current[index - 1]} />
        }

        answers.push(
            <Stack key={"stack" + index} direction="row">
                <TextField key={"answer " + index} inputRef={answerTextRefs.current[index - 1]} margin="dense" label={`Answer ${index}`} defaultValue={answer.text} type="text" fullWidth variant="standard" />
                <Tooltip key={"tooltip" + index} title="Is Correct?">
                    { isCorrectSwitch }
                </Tooltip>
                <Tooltip title="Delete Answer">
                    <IconButton key={"tooltip" + index} sx={{ color: 'red' }}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )

        index++
    }

    for (var i = 0; i < extra; i++) {
        answerTextRefs.current[index - 1] = createRef()
        answerIsCorrectRefs.current[index - 1] = createRef()

        answers.push(
            <Stack direction="row">
                <TextField key={"answer " + index} inputRef={answerTextRefs.current[index - 1]} margin="dense" label={`Answer ${index}`} type="text" fullWidth variant="standard" />
                <Tooltip title="Is Correct?">
                    <Switch key={"isCorrect " + index} inputRef={answerIsCorrectRefs.current[index - 1]} />
                </Tooltip>
                <Tooltip title="Delete Answer">
                    <IconButton sx={{ color: 'red' }}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )

        index++
    }

    const onAddAnswer = () => {
        setExtra(extra + 1)
    }

    return (
        <Dialog open={modal.visible} >
            <DialogTitle>Editing {props.pollId}</DialogTitle>
            <DialogContent>
                <TextField inputRef={questionTextFieldRef} autoFocus margin="dense" label="Question" defaultValue={poll.question} type="text" fullWidth variant="standard" />
                <TextField inputRef={descriptionTextFieldRef} margin="dense" label="Description" type="text" defaultValue={poll.description} fullWidth variant="standard" />
                <TextField inputRef={groupTextFieldRef} margin="dense" label="Group" type="text" defaultValue={poll.group} fullWidth variant="standard" />

                <Stack mt={4} spacing={1}>
                    { answers }
                    <Button onClick={ () => onAddAnswer()}><AddCircleIcon/> Add Answer</Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={saveButtonDisabled} onClick={ () => saveChanges()}>Save</Button>
                <Button disabled={discardButtonDisabled} onClick={ () => modal.remove()}>Discard</Button>
            </DialogActions>
        </Dialog>
    );
});

