import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { API } from 'aws-amplify'

export const VoteButton = (props) => {
    const pollId = props.pollId.toLowerCase();
    async function onButtonClick() {
        props.setButtonLoading(true);
        const myInit = {
            body: {
                pollId: pollId,
                answerId: props.answerId,
            },
        };

        try {
            await API.put('polls', '/poll/vote', myInit)
            window.location.reload(false);
            const localData = {
                'date': new Date(),
                'question': props.question,
                'answer_given': props.answerText
            };

            localStorage.setItem(pollId, JSON.stringify(localData));
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <LoadingButton 
            key={props.btnkey} 
            variant="contained" 
            style={{minHeight: '50px', fontSize: props.enlarge ? '35px' : '15px', textTransform: 'none'}} 
            loading={props.buttonLoading} 
            onClick={() => onButtonClick()}>
                {props.answerText}
        </LoadingButton>
    );
};

