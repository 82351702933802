import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { API } from 'aws-amplify'
import { Loader } from './loader';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify'

function LinearProgressWithLabel(answer) {
    const label = answer.votes === 1 ? 'vote' : 'votes';
    const color = answer.iscorrect === 'true' ? 'success' : 'primary';
    const text = answer.iscorrect === 'true' ? <b>{answer.text}</b> : answer.text;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <Typography variant="h4">{text}</Typography>
                <LinearProgress style={{height: "80px"}} color={color} variant="determinate" {...answer} />
            </Box>
            <Box sx={{ minWidth: 120, marginTop: 5 }}>
                <Stack display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
                    <Typography variant="h4" color="text.secondary">{`${Math.round(
                      answer.value,
                    )}%`}</Typography>
                    <Typography variant="h5" color="text.secondary">({answer.votes} {label})</Typography>
                </Stack>
            </Box>
        </Box>
    );
}

export const Results = (props) => {
    var { pollId } = useParams();
    pollId = pollId.toLowerCase();
    const [isLoading, setLoading] = useState(true);
    const [poll, setPoll] = useState();

    const navigate = useNavigate();
    useEffect(() => {
       Auth.currentAuthenticatedUser()
       .catch(err => navigate(`/${pollId}`));

       fetchPoll();
    }, []);

    async function fetchPoll() {
        const data = await API.get('polls', `/poll/${pollId}`)
        .catch(error => {});
        setPoll(data);
        setLoading(false);
    }


    if (isLoading) {
        return <Loader />;
    }

    document.title = poll.question;

    const answers = poll.answers;

    var totalVotes = 0;
    for (var answerId in answers) {
        const answer = answers[answerId]
        const votes = parseInt(answer.votes);
        totalVotes += votes;
    }

    const sortedAnswers = Object.keys(answers)
        .map(key => { return {key, val: answers[key]}})
        .sort((a, b) => a.val.votes < b.val.votes); 

    const progressBars = [];
    for (var i in sortedAnswers) {
        const answerId = sortedAnswers[i].key;
        const answer = poll.answers[answerId]
        const votes = parseInt(answer.votes);
        const text = answer.text;
        const key = `progressBar${answerId}`;

        var percentage = 0;
        if(totalVotes > 0)
            percentage = votes / totalVotes * 100;

        progressBars.push(<LinearProgressWithLabel 
                                key={key} 
                                value={percentage} 
                                text={text} 
                                votes={votes} 
                                iscorrect={answer.isCorrect.toString()} 
                          />);
    }

    return (
        <Container component="main" maxWidth="lg">
            <Stack sx={{ width: '100%' }} spacing={1}>
                <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
                    <Typography variant="h3" component="div" gutterBottom>
                        {poll.question}
                    </Typography>
                </Box>

                {progressBars}

                <Box sx={{ paddingTop: 1, textAlign: 'center' }}>
                    <Typography variant="h4" component="div">
                        <b>Total votes:</b> {totalVotes}
                    </Typography>

                </Box>
            </Stack>
        </Container>
    )
};
