import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export const PollNotFound = ({pollId}) => {
    const [newPollId, setNewPollId] = useState('');
    const navigate = useNavigate();

    function onSubmit(event) {
        navigate(`/${newPollId}`);
    }

    return (
        <Container component="main" maxWidth="lg">
            <Stack sx={{ width: '100%' }} spacing={2} display="flex" justifyContent="center" alignItems="center">
                <img alt="Poll Not Found" width="200" src="/not-found.png"/>
                <Typography variant="h4" component="div">
                    Poll '{pollId}' not found, try another one 👇
                </Typography>
                <form onSubmit={onSubmit} >
                    <TextField sx={{ width: {sm: 400, xs: 320} }} id="outlined-basic" autoFocus={true} onInput={ e=>setNewPollId(e.target.value)} label="Try another poll ID?" variant="outlined" />
                </form>
            </Stack>
        </Container>
    );
};
