import React, { useState, useRef, createRef } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { API } from 'aws-amplify'


export const AddModal = NiceModal.create(({ pollId }) => {
    const questionTextFieldRef = useRef();
    const descriptionTextFieldRef = useRef();
    const groupTextFieldRef = useRef();
    const answerTextRefs = useRef([])
    const answerIsCorrectRefs = useRef([])
    const modal = useModal();
    const [extra, setExtra] = useState(1);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [discardButtonDisabled, setDiscardButtonDisabled] = useState(false);

    async function saveChanges() {
        setSaveButtonDisabled(true)
        setDiscardButtonDisabled(true)
        const question = questionTextFieldRef.current.value
        const description = descriptionTextFieldRef.current.value
        const group = groupTextFieldRef.current.value

        const data = {
            body: {
                question: question,
                description: description,
                group: group,
                answers: {}
            },
        };

        for(var i=0; i < answerTextRefs.current.length; i++) {
            const answer = answerTextRefs.current[i].current.value
            const isCorrect = answerIsCorrectRefs.current[i].current.checked
            data.body.answers[i] = {text: answer, isCorrect: isCorrect, votes: 0}
        }

        try {
            await API.post('polls', '/poll/create', data)
            modal.resolve()
            modal.remove()
        } catch (error) {
            console.log(error)
        }
    }

    var answers = []
    for (var i = 0; i < extra; i++) {
        answerTextRefs.current[i] = createRef()
        answerIsCorrectRefs.current[i] = createRef()
        answers.push(
            <Stack key={"stack" + i} direction="row">
                <TextField key={"answer " + i} inputRef={answerTextRefs.current[i]} margin="dense" label={`Answer ${i + 1}`} type="text" fullWidth variant="standard" />
                <Tooltip title="Is Correct?">
                    <Switch key={"isCorrect " + i} inputRef={answerIsCorrectRefs.current[i]} />
                </Tooltip>
                <Tooltip title="Delete Answer">
                    <IconButton sx={{ color: 'red' }}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    const onAddAnswer = () => {
        setExtra(extra + 1)
    }

    return (
        <Dialog open={modal.visible} >
            <DialogTitle>Adding New Poll</DialogTitle>
            <DialogContent>
                <TextField inputRef={questionTextFieldRef} autoFocus margin="dense" label="Question" type="text" fullWidth variant="standard" />
                <TextField inputRef={descriptionTextFieldRef} margin="dense" label="Description" type="text" fullWidth variant="standard" />
                <TextField inputRef={groupTextFieldRef} margin="dense" label="Group" type="text" fullWidth variant="standard" />

                <Stack mt={4} spacing={1}>
                    { answers }
                    <Button onClick={ () => onAddAnswer()}><AddCircleIcon/> Add Answer</Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={saveButtonDisabled} onClick={ () => saveChanges()}>Save</Button>
                <Button disabled={discardButtonDisabled} onClick={ () => modal.remove()}>Discard</Button>
            </DialogActions>
        </Dialog>
    );
});

