import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from "react-router-dom";
import { View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme
import '@aws-amplify/ui-react/styles.css';
import { useTheme, Heading, Authenticator, useAuthenticator, Button } from '@aws-amplify/ui-react';


const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.medium}>
        <Heading level={1} >
        Poll Console
        </Heading>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
}


export const Login = (props) => {
    document.title = "Poll...";
    const navigate = useNavigate();

      const services = {
        async handleSignIn(formData) {
          let { username, password } = formData;
          Auth.signIn({username, password})
          .then(state => {
              navigate("/console")
          })
          .catch(err => {
          })
        },
      };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then(() => {
            navigate("/console");
        })
       .catch(err => {})
    }, [])

    return (
        <Authenticator components={components} hideSignUp={true} services={services} />
    );
};

