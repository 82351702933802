import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify'
import { AdvertiseURL } from './advertise_url';
import { Votes } from './votes';
import { Loader } from './loader';
import { PollNotFound } from './not_found';
import { CastedPoll } from './casted_poll';
import {
    useParams,
    useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ShowPoll(pollId) {
    pollId = pollId.toLowerCase();
    const [isLoading, setLoading] = useState(true);
    const [poll, setPoll] = useState();
    const query = useQuery();
    var advertiseURL = query.get('advertiseURL') !== null ? true : false;

    async function fetchPoll() {
        try {
            const data = await API.get('polls', `/poll/${pollId}`)
            setPoll(data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPoll()
    }, []);

    if (isLoading) {
        return <Loader />;
    } else if(!poll) {
        return <PollNotFound pollId={pollId} />;
    }

    document.title = poll.question;

    return (
        <Container component="main" maxWidth="lg">
            <Stack sx={{ width: '100%' }} spacing={1}>
                <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
                    <Typography variant={advertiseURL ? "h3" : "h5"} component="div" gutterBottom>
                        {poll.question}
                    </Typography>
                </Box>
            
                <Votes pollId={pollId} question={poll.question} answers={poll.answers} enlarge={advertiseURL} />
                
                {advertiseURL ? <AdvertiseURL pollID={pollId} /> : null}
            </Stack>
        </Container>
    );
}

function localDataTooOld(localData) {
    const voteDate = Date.parse(localData['date']);
    const dateNow = new Date();
    const diffTime = Math.abs(dateNow - voteDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 31;
}

export const Poll = (props) => {
    document.title = "Poll...";
    var { pollId } = useParams();
    pollId = pollId.toLowerCase();
    const localData = JSON.parse(localStorage.getItem(pollId));

    if ( !localData || localDataTooOld(localData) ) {
        localStorage.removeItem(pollId);
        return ShowPoll(pollId);
    } else {
        document.title = localData['question'];
        return <CastedPoll question={localData['question']} answer={localData['answer_given']} />;
    } 
};

