import React from "react";
import { Constants } from '../constants';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import QRCode from "react-qr-code";
import Link from '@mui/material/Link';

export const AdvertiseURL = ({ pollID }) => {
    const url=`${Constants.baseURL}/${pollID}`;
    const simplifiedUrl = url.replace(/(^\w+:|^)\/\//, '')

    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 5}}>
            <Alert icon={false} severity="success">
                <Stack display="flex" justifyContent="center" alignItems="center" spacing={1}>
                    <Typography variant="h3" component="div">
                        Scan me to vote
                    </Typography>
                    <QRCode size={200} value={url} />
                    <Typography variant="h3" component="div">
                        <Link underline="none" href={url}>
                            <b>{simplifiedUrl}</b>
                        </Link>
                    </Typography>
                </Stack>
            </Alert>
        </Box>
    );
};
