import React from "react";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export const Footer = () => {
    return (
        <Stack style={{ marginTop: 40 }} spacing={1}>
            <nav role="navigation">
                <ul className="nav justify-content-center">
                    <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer"  href="https://twitter.com/rpapallas" title="Twitter"><i className="fab fa-twitter fa-2x"></i><span className="menu-title sr-only">Twitter</span></a></li>
                    <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/rpapallas" title="LinkedIn"><i className="fab fa-linkedin-in fa-2x"></i><span className="menu-title sr-only">LinkedIn</span></a></li>
                    <li className="nav-item"><a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://www.github.com/rpapallas" title="GitHub"><i className="fab fa-github fa-2x"></i><span className="menu-title sr-only">GitHub</span></a></li>
                </ul>
            </nav>
            <Typography display="flex" justifyContent="center" variant="body1" color="text.secondary">
                {`Copyright © ${new Date().getFullYear()}`} &nbsp; <Link underline="none" href="https://rpapallas.com">Rafael Papallas</Link>
            </Typography>
        </Stack>
    );
};
