import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Auth } from 'aws-amplify'
import { Poll } from './components/poll';
import { Results } from './components/results';
import { Footer } from './components/footer';
import { Console } from './components/console/console'
import { Login } from './components/console/login'
import './index.css';
import config from './aws-exports'
import Amplify from 'aws-amplify'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
Amplify.configure(config)

function IndexPage(props) {
    const [newPollId, setNewPollId] = useState('');
    const navigate = useNavigate();

    function onSubmit(event) {
        navigate(`/${newPollId}`);
    }

   return (
        <Container component="main" maxWidth="lg">
         <Stack style={{ marginTop: 40 }} sx={{ width: '100%' }} spacing={2} display="flex" justifyContent="center" alignItems="center">
            <Typography display="flex" justifyContent="center" variant="h4">👋 You need a Poll ID</Typography>
            <Typography display="flex" justifyContent="center" variant="h5">Type it here 👇</Typography>
             <form onSubmit={onSubmit} >
                 <TextField sx={{ width: {sm: 400, xs: 320} }} id="outlined-basic" autoFocus={true} onInput={ e=>setNewPollId(e.target.value)} label="Poll ID (press 'return' on your keyboard)" variant="outlined" />
             </form>
        </Stack>
        </Container>
   );
}

function Header(props) {
   const isLoggedIn = props.isLoggedIn;
   if (isLoggedIn) {
      return (
    <React.Fragment>
        <header className="bg-light">
            <nav className="navbar navbar-expand-lg navbar-light bg-light" id="header-nav" role="navigation">
                <div className="container">
                    <a class="link-dark navbar-brand site-title" href="/">Rafael Papallas</a>

                    <ul className="navbar-nav ms-auto me-2">
                        <li className="nav-item"><a className="nav-link" href="https://rpapallas.com">Home</a></li>
                        <li className="nav-item"><a className="nav-link" href="https://polls.rpapallas.com/console">Console</a></li>
                    </ul>
                </div>
            </nav>
        </header>
    </React.Fragment>
      );
   } else {
      return (
       <React.Fragment>
           <header className="bg-light">
               <nav className="navbar navbar-expand-lg navbar-light bg-light" id="header-nav" role="navigation">
                   <div className="container">
                       <span className="link-dark navbar-brand site-title mb-0">
                           Rafael Papallas
                       </span>

                       <ul className="navbar-nav ms-auto me-2">
                           <li className="nav-item"><a className="nav-link" href="https://rpapallas.com">Home</a></li>
                       </ul>
                   </div>
               </nav>
           </header>
       </React.Fragment>
      );
   }
}

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
       Auth.currentAuthenticatedUser()
       .then(data => setIsAuthenticated(true))
       .catch(err => setIsAuthenticated(false));
    }, []);

    return ( 
        <React.Fragment>
            <Router>
                <Routes>
                    <Route path="/" element={
                        <React.Fragment>
                            <Header isLoggedIn={isAuthenticated} />
                            <IndexPage />
                            <Footer/>
                        </React.Fragment>
                    } />

                    <Route path="/console" element={
                        <React.Fragment>
                            <Console/>
                            <Footer/>
                        </React.Fragment>
                    } />

                    <Route path="/console/login" element={
                        <React.Fragment>
                            <Login/>
                            <Footer/>
                        </React.Fragment>
                    } />

                    <Route path="/:pollId" element={
                        <React.Fragment>
                            <Header isLoggedIn={isAuthenticated} />
                            <Poll/>
                            <Footer/>
                        </React.Fragment>
                    } />

                    <Route path="/:pollId/results" element={
                        <React.Fragment>
                            <Header isLoggedIn={isAuthenticated} />
                            <Results/>
                            <Footer/>
                        </React.Fragment>
                    } />
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default App
