import React from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const CastedPoll = (props) => {
    return (
        <Stack sx={{ width: '100%' }} spacing={1} display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ paddingTop: 5, textAlign: 'center' }}>
                <Typography variant="h4" component="div" gutterBottom>
                    {props.question}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: 2, width: "80%", textAlign: 'center' }}>
                <Alert icon={false} severity="success">
                    <Typography variant="h6" component="div">
                    Thank you, your vote is casted! You said: '{props.answer}'
                    </Typography>
                </Alert>
            </Box>
        </Stack>
    )
};
