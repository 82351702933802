import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddModal } from './add_modal'
import { Loader } from '../loader';
import { API } from 'aws-amplify'
import { EditModal } from './edit_modal'
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Constants } from '../../constants.js'
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NiceModal from '@ebay/nice-modal-react';
import Tooltip from '@mui/material/Tooltip';

function createData(question: string, 
                    description: string,
                    pollId: string, 
                    totalVotes: number, 
                    group: string) {
    return { question, description, pollId, totalVotes, group };
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


export const ConsoleTable = (props) => {
    async function deletePoll(pollId) {
        try {
            setLoading(true)
            await API.del('polls', `/poll/delete/${pollId}`)
            fetchPolls()
        } catch (error) {
            console.log(error);
        }
    }

    async function resetPoll(pollId) {
        try {
            setLoading(true)
            await API.put('polls', `/poll/reset/${pollId}`)
            fetchPolls()
        } catch (error) {
            console.log(error);
        }
    }

    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState(false);


    async function fetchPolls() {
        try {
            setLoading(true)
            const data = await API.get('polls', '/polls/')

            const pollRows = [];
            for (var i in data) {
                const poll = data[i];
                pollRows.push(
                    createData(poll.question, poll.description, poll.id, poll.totalVotes, poll.group)
                );
            }

            setRows(pollRows);
            setLoading(false);
        } catch (err) {
            console.log(err)
        }
    }

    const showAddModal = (pollId) => {
        // Show a modal with arguments passed to the component as props
        NiceModal.show(AddModal).then(() => {
            fetchPolls()
        });
    };


    const showEditModal = (pollId) => {
        // Show a modal with arguments passed to the component as props
        NiceModal.show(EditModal, { pollId: pollId, load: true }).then(() => {
            fetchPolls()
        });
    };


    useEffect(() => {
        fetchPolls()
    }, [])

    if (isLoading)
        return <div><Loader /></div>

    return (
        <Container>
            <Stack spacing={2}>
                <Button variant="text" onClick={() => showAddModal()} >
                    <AddCircleIcon/> Add New Poll
                </Button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><b>Question</b></StyledTableCell>
                                <StyledTableCell><b>Description</b></StyledTableCell>
                                <StyledTableCell align="right"><b>Poll ID</b></StyledTableCell>
                                <StyledTableCell align="right"><b>Total Votes</b></StyledTableCell>
                                <StyledTableCell align="right"><b>Group</b></StyledTableCell>
                                <StyledTableCell align="center"><b>Actions</b></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.pollId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell component="th" scope="row">
                                        {row.question}
                                    </TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell align="right"><Link rel="noopener" target="_blank" href={`${Constants.baseURL}/${row.pollId}?advertiseURL`}>{row.pollId}</Link></TableCell>
                                    <TableCell align="right">{row.totalVotes}</TableCell>
                                    <TableCell align="right">{row.group}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => showEditModal(row.pollId)} >
                                                <EditIcon />
                                            </IconButton> 
                                        </Tooltip>

                                        <Tooltip title="Rest votes to 0">
                                            <IconButton onClick={() => resetPoll(row.pollId)} >
                                                <RestartAltIcon/>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete">
                                            <IconButton sx={{ color: 'red' }} onClick={() => deletePoll(row.pollId)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
        </Container>
    );
}
