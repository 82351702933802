import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export const Loader = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="20vh">
            <CircularProgress size={150} />
        </Box>
    );
};
