import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify'

function updateStatusBarColour() {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", "#1976d2");
}

export default function PrimarySearchAppBar() {
    updateStatusBarColour()
    const navigate = useNavigate();

    async function signOut() {
        try {
            await Auth.signOut()
            navigate("/console/login")
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        Polls Console
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <Stack direction="row" >
                        <Tooltip title="Logout">
                            <IconButton onClick={signOut} size="small" color="inherit">
                                <ExitToAppIcon/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
